import API from '@/services/api'
import { relocateToLogin } from '@/helpers/navguard'
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

let keyStorage

const nullConfig = {
  useZenoAuth: null,
  contextPath: null,
  cookieName: null
}

const state = {
  authenticated: false,
  authenticating: false,
  groupsWithRadioLinks: [],
  isUserWithMisconfiguredGroup: [],
  user: null,
  config: nullConfig,
  preselectedContextRadioSite: {
    radioId: null,
    siteId: null
  }
}

export const actions = {
  authenticate ({ dispatch, commit, getters, store }, { endThen, onError }) {
    API.authenticate().then(r => {
      commit('setUser', r.data)
      commit('setAuthenticated', true)
      commit('setAuthenticating', false)
      dispatch('initializePersistence', false)
      API.getGroupsWithRadioLinksByUserId(r.data.id).then(r2 => {
        commit('setgroupsWithRadioLinks', r2.data)
        API.isUserWithMisconfiguredGroup(r.data.id).then(r3 => {
          commit('isUserWithMisconfiguredGroup', r3.data)
        })
      })
      endThen()
    }).catch(onError)
  },
  // Initialize persistence dynamically after userId is set
  // 'create' is a boolean: if true, we create the key localstorage if not existing, else we only get value of the localstorage if key exist
  // eslint-disable-next-line no-empty-pattern
  initializePersistence ({}, create) {
    keyStorage = `preselectedContext-${this.state.user.id}`
    // Create a persisted state instance with userId-specific key
    if ((!create && localStorage.getItem(keyStorage) !== null) || (create && localStorage.getItem(keyStorage) === null)) {
      const persistedState = createPersistedState({
        key: keyStorage, // Dynamic unique key
        paths: ['preselectedContextRadioSite'],
        filter: (mutation) => {
          // Only persist mutations that don't involve clearing the key
          // Without it, it would delete the key and recreate it
          return mutation.type !== 'deletePreselectedContextRadioSite'
        }
      })
      // Call the plugin to re-persist the state
      persistedState(store)
    }
  },
  updatePreselectedContextRadioSite ({ dispatch, commit }, newPreselectedContextRadioSite) {
    dispatch('initializePersistence', true)
    commit('setPreselectedContextRadioSite', newPreselectedContextRadioSite)
  },
  routerAuthenticate ({ dispatch, commit, getters }, next) {
    dispatch('initConfig').then(() => {
      dispatch('authenticate', {
        endThen: next,
        onError () {
          commit('setAuthenticated', false)
          commit('setAuthenticating', true)
          if (!getters.isZenoAuthUsed) {
            next('/ui/login')
          }
        }
      })
    })
  },
  login ({ dispatch }, credentials) {
    return API.login(credentials).then(() => {
      dispatch('authenticate', {
        endThen () {},
        onError () {}
      })
    })
  },
  logout ({ commit }) {
    commit('setAuthenticated', false)
    API.logout().then(relocateToLogin)
  },
  initConfig ({ commit }) {
    return API.getConfig().then(r => {
      commit('setConfig', r.data)
    })
  },
  notAuthenticatingAnyMore ({ commit }) {
    commit('setAuthenticating', false)
  },
  secureLogin ({ commit, dispatch, getters }, next) {
    dispatch('initConfig').then(() => {
      if (getters.isZenoAuthUsed) {
        relocateToLogin()
      } else {
        next()
      }
    })
  }
}

export const mutations = {
  setUser (state, user) {
    state.user = Object.assign({}, {
      id: user.id,
      userName: user.userName,
      login: user.name,
      admin: user.admin
    })
  },
  setAuthenticated (state, authenticated) {
    state.authenticated = authenticated
  },
  setAuthenticating (state, authenticating) {
    state.authenticating = authenticating
  },
  setgroupsWithRadioLinks (state, groupsWithRadioLinks) {
    state.groupsWithRadioLinks = groupsWithRadioLinks
  },
  isUserWithMisconfiguredGroup (state, isUserWithMisconfiguredGroup) {
    state.isUserWithMisconfiguredGroup = isUserWithMisconfiguredGroup
  },
  setConfig (state, config) {
    state.config = config
  },
  setPreselectedContextRadioSite (state, preselectedContextRadioSite) {
    state.preselectedContextRadioSite = preselectedContextRadioSite
  },
  deletePreselectedContextRadioSite () {
    state.preselectedContextRadioSite = {
      radioId: null,
      siteId: null
    }
    localStorage.removeItem(keyStorage)
  }
}

export const getters = {
  isZenoAuthUsed (state) {
    return state.config.useZenoAuth
  },
  getContextPath (state) {
    return state.config.contextPath
  },
  getCookieName (state) {
    return state.config.cookieName
  },
  isAuthenticating (state) {
    return state.authenticating
  },
  isConfigUnset (state) {
    return state.config === nullConfig
  }
}

const store = new Vuex.Store({
  state: state,
  actions: actions,
  mutations: mutations,
  getters: getters
})

export default store
